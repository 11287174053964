import * as React from "react";

import "./QuizSchedule.scss";
import "./ShootingStars.scss";
import { inject } from "mobx-react";
import {
  GameTypesList,
  handleErrors,
  setAuthUser,
  urlParamsAsync,
} from "../../services/HelperMethodsService";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";
import { Fragment, useState } from "react";
import ApiService from "../../services/ApiService";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

function QuizSchedule() {
  const [askPassword, setAskPassword] = useState(true);
  const [password, setPassword] = useState("");
  const apiService = new ApiService();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  return (
    <div className="quiz-schedule-wrapper">
      <div className="catm-logo"></div>
      <div className="btn-wrapper">
        <div
          className="game-icon"
          onClick={() => {
            window.open("/#/entry", "HollywoodQuiz", "width=960,height=539");
            // history.push("/entry");
          }}
        >
          {/* <div className="game-name-hq">Play with Friends Hollywood Quiz</div> */}
        </div>

        <div
          className="fifty-icon"
          onClick={() => {
            // if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
            //   {window.open(`https://fiftydemo.catmonocle.com/#/set/${localStorage.getItem('auth')}`, "HollywoodQuiz", "width="+screen.availWidth+",height="+screen.availHeight+"");}
            //  }
            //  else
            //  {
            //   {window.open(`https://fiftydemo.catmonocle.com/#/set/${localStorage.getItem('auth')}`, "HollywoodQuiz", "width=1200,height=600");}
            //  }

            window.open(
              `https://fiftydemo.catmonocle.com/#/set/${localStorage.getItem(
                "auth"
              )}`,
              "5050 Multiplayer",
              "width=1200,height=680"
            );
            //window.open(`http://demo.catmonocle.com:8082/#/set/${localStorage.getItem('auth')}`, "HollywoodQuiz", "width=960,height=539");
            // history.push("/entry");
          }}
        >
          {/* <div className="game-name-5050">50/50 Music</div> */}
        </div>

        {/* <div
          className="fifty-single-icon"
          onClick={() => {
            // if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
            //   {window.open(`https://fiftydemo.catmonocle.com/#/set/${localStorage.getItem('auth')}`, "HollywoodQuiz", "width="+screen.availWidth+",height="+screen.availHeight+"");}
            //  }
            //  else
            //  {
            //   {window.open(`https://fiftydemo.catmonocle.com/#/set/${localStorage.getItem('auth')}`, "HollywoodQuiz", "width=1200,height=600");}
            //  }

            window.open(
              `https://fiftysingleplayerdemo.catmonocle.com/?Token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpYXQiOjE2NzQ3MjIzNjksImp0aSI6IkswbXEydnpLd3dZdEhcL2Z5clNXakZramVoUU03U0ZaZTlaWDZrNWVCMmVnPSIsImlzcyI6ImlmZy5sayIsIm5iZiI6MTY3NDcyMjM2OSwiZXhwIjoxNzA2MjU4MzY5LCJkYXRhIjp7InVzZXJfaWQiOiI0MTllMzc3Ni05MTU2LTQ3ZDMtOWYyMC1hM2JmNTE1MDJiOTEiLCJhcHBfaWQiOiJpbnNmcmVlZ2FtZSJ9fQ.ZznlxApyilKWWQQj_tTmU3l1lSWMOoMRnhDU3Q6E2IyRU8wqo_njxWQ6O1FfD2cTJIRurnqV42F2GTiNKL9oTQ&GameType=19&NetworkID=25&GameLanguage=en&PlayerID=629&cashierURL=&SkinId=&DisplayName=test&RealityCheckFrequency=&country=LKA&SkinId=&GameCurrency=GBP&width=960&height=400&OperationSourceApplication=#/`,
              "5050 SinglePlayer",
              "width=1200,height=680"
            );
            //window.open(`http://demo.catmonocle.com:8082/#/set/${localStorage.getItem('auth')}`, "HollywoodQuiz", "width=960,height=539");
            // history.push("/entry");
          }}
        >
          <div className="game-name-5050">50/50 Music</div>
        </div> */}
      </div>

      {AskPasswordUI()}
    </div>
  );

  async function clickEnter() {
    if (!password) {
      return toast.error("Please enter a valid password");
    }

    setIsLoading(true);
    try {
      let data = await apiService.auth({ password: password });
      data = data.data;
      console.log("auth data", data);
      setAuthUser(data);
      setAskPassword(false);
    } catch (error) {
      handleErrors(error);
    }
    setIsLoading(false);
  }

  function AskPasswordUI() {
    if (!askPassword) {
      return <Fragment />;
    }

    return (
      <div className="modal fade show">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            clickEnter();
          }}
        >
          <div className="mt-3">
            <input
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              type="password"
              placeholder="Password"
              className="form-control"
            />
          </div>
          <div className="mt-5">
            <button
              type="submit"
              disabled={isLoading}
              style={{
                textTransform: "uppercase",
                letterSpacing: 5,
                fontWeight: "bold",
              }}
              className="w-100 p-3 btn btn-primary"
            >
              {isLoading ? (
                <div className="circle">
                  <i className="fas fa-spinner fa-spin" />
                </div>
              ) : (
                <div>Enter</div>
              )}
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default QuizSchedule;
