import React, { createRef, useEffect, useRef, useState } from "react";

import "./PlayerPlacements.scss";
import { threadId } from "worker_threads";
import { formatScore } from "../../services/HelperMethodsService";

interface PlayerPlacementProps {
  players: any[];
  gameType: string;
  myPlayerId: number;
}

export function PlayerPlacements(props: PlayerPlacementProps) {
  // filter players based on width
  let playerPlacement = useRef(null);
  const [displayPlayers, setDisplayPlayers] = useState([] as any[]);
  const [resizeChange, setResizeChange] = useState(0);

  function eventListener() {
    setResizeChange(Math.random());
  }
  useEffect(() => {
    window.addEventListener("resize", eventListener);

    return () => {
      window.removeEventListener("resize", eventListener);
    };
  }, []);

  useEffect(() => {
    // let width = (playerPlacement.current as any).getBoundingClientRect()
    //     .width;
    // let numberOfItemsFit = Math.floor(width / 80);
    // let displayPlayers = props.players.slice(0, numberOfItemsFit);
    // setDisplayPlayers(displayPlayers);

    // calculate
    let width = (playerPlacement.current as any).getBoundingClientRect().width;

    let numberOfItemsFit = Math.floor(width / 90);
    let playerPosition;
    let totalPlayers;
    let p = null;
    props.players.forEach((player, index) => {
      if (player?.gamePlayerId == props.myPlayerId) {
        playerPosition = index;
        p = player;
      }
      totalPlayers = index + 1;
    });

    let displayPlayers = [] as any[];
    if (!props.players?.length) {
      return;
    }
    if (numberOfItemsFit < totalPlayers) {
      if (playerPosition < numberOfItemsFit) {
        displayPlayers = props.players.slice(0, numberOfItemsFit + 1);
      } else {
        // get players first to the player
        displayPlayers.push(props.players[playerPosition]);

        for (var i = 1; i <= numberOfItemsFit; i++) {
          var prevPlayerIndex = playerPosition - i;
          if (props.players.length > prevPlayerIndex) {
            let prevPlayer = props.players[prevPlayerIndex];
            displayPlayers.unshift(prevPlayer);
          }
        }

        if (displayPlayers.length < numberOfItemsFit) {
          var diff = numberOfItemsFit - displayPlayers.length;
          for (var i = 1; i <= diff; i++) {
            var nextPlayerIndex = playerPosition + i;
            if (props.players.length > nextPlayerIndex) {
              let nextPlayer = props.players[nextPlayerIndex];
              displayPlayers.push(nextPlayer);
            }
          }
        } else {
          // display behind one
          var prevPlayer = playerPosition + 1;
          if (props.players.length > prevPlayer) {
            let nextPlayer = props.players[prevPlayer];
            displayPlayers.push(nextPlayer);
            displayPlayers.shift();
          }
        }
      }

      // displayPlayers = props.players.slice(
      //     totalPlayers - numberOfItemsFit
      // );
      // var isPlayerIn = props.players.find(t=> t.gamePlayerId == props.myPlayerId);
      // if(!isPlayerIn){
      //     displayPlayers.
      // }
    } else {
      displayPlayers = props.players;
    }

    // if mobile
    if (width < 100) {
      let firstPlayer = null as any;
      let secondPlayer = null as any;
      let thirdPlayer = null as any;

      if (totalPlayers >= 2 && playerPosition != 0) {
        firstPlayer = props.players[playerPosition - 1];
        secondPlayer = props.players[playerPosition];
        thirdPlayer = props.players[playerPosition + 1];
      }
      if (totalPlayers >= 2 && playerPosition == 0) {
        firstPlayer = props.players[playerPosition];
        secondPlayer = props.players[playerPosition + 1];
        thirdPlayer = props.players[playerPosition + 2];
      }
      if (totalPlayers == 0) {
        firstPlayer = props.players[playerPosition];
      }
      if (totalPlayers == 1) {
        if (playerPosition == 0) {
          firstPlayer = props.players[playerPosition];
          secondPlayer = props.players[playerPosition + 1];
        } else {
          firstPlayer = props.players[playerPosition - 1];
          secondPlayer = props.players[playerPosition];
        }
      }
      if (firstPlayer && secondPlayer && thirdPlayer) {
        displayPlayers = [firstPlayer, secondPlayer, thirdPlayer];
      } else if (firstPlayer && secondPlayer && !thirdPlayer) {
        displayPlayers = [firstPlayer, secondPlayer];
      } else if (firstPlayer && !secondPlayer && !thirdPlayer) {
        displayPlayers = [firstPlayer];
      }
    }

    setDisplayPlayers(displayPlayers);
  }, [props.players, resizeChange]);

  return (
    <div className="placement-wrap">
      <div ref={playerPlacement} className="player-placements">
        {displayPlayers.map((player: any) => {
          let customClass = "n" + props.gameType;
          let playerName = player?.playerName;
          if (player?.gamePlayerId == props.myPlayerId) {
            customClass += " active";
            // playerName = "You";
          }

          const content = (
            <>
              <div className={"number n" + props.gameType}>
                <div className={"game-position n" + props.gameType}>
                  {player?.gamePosition}
                </div>
              </div>
              <div className="score">
                <div className="player-name">{playerName}</div>
                <div className="player-score">
                  {formatScore(player?.playerTotalScore)}
                </div>
              </div>
            </>
          );

          return (
            <div
              className={"profile-user " + customClass}
              key={player?.gamePlayerId}
            >
              {content}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export function TopPlayers(props: PlayerPlacementProps) {
  function getPlayerPositionImage(player: any) {
    if (player?.gamePosition == 1) {
      return <div className="ui_firstplace" />;
    } else if (player?.gamePosition == 2) {
      // return <div className="second">{player?.gamePosition}</div>;
      return <div className="ui_firstplace secondp" />;
    } else if (player?.gamePosition == 3) {
      // return <div className="third">{player?.gamePosition}</div>;
      return <div className="ui_firstplace thirdp" />;
    }
  }

  return (
    <div className="top-players">
      {props.players.slice(0, 3).map((player: any) => {
        const content = (
          <>
            <div className={"game-position n" + props.gameType}>
              {getPlayerPositionImage(player)}
            </div>
            <div className="player-name">{player?.playerName}</div>
            <div className="player-score">
              {formatScore(player?.playerTotalScore)}
            </div>
          </>
        );

        return (
          <div className="profile-user" key={player?.gamePlayerId}>
            {content}
          </div>
        );
      })}
    </div>
  );
}
