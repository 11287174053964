import { exception } from "console";
import { connect } from "http2";
import { getServerName } from "./../HelperMethodsService";
import Base from "./BaseSocketService";

export default class ChatSocketService {
  base = Base();
  isChatConnected = false;
  static instance: ChatSocketService;

  static getInstance() {
    if (ChatSocketService.instance == null) {
      ChatSocketService.instance = new ChatSocketService();
    }

    // if (!ChatSocketService.instance.isChatConnected) {
    //     ChatSocketService.instance.connect(
    //         () => {},
    //         () => {}
    //     );
    // }

    return ChatSocketService.instance;
  }

  connect(
    afterConnect: CallableFunction,
    afterDisconnect: CallableFunction = () => {}
  ) {
    // InstanceStore.ChatSocketInstance = this;
    if (!this.isChatConnected) {
      this.base.connect(
        "chat",
        () => {
          this.isChatConnected = true;
          // this.playerBalanceSubscribe((balance) => {
          //   // console.log("player balance", balance);
          // });
          // setInterval(() => {
          //   this.checkBalance();
          // }, 1000 * 60);
          // afterConnect();
        },
        afterDisconnect
      );
    } else {
      // console.error("chat trying to establish twice");
    }
  }

  receiveMessageToLobby(cb: CallableFunction) {
    this.base.on("ReceiveMessageToLobby", (data: any) => {
      console.log("message", data);
      if (data.serverName == getServerName()) {
        cb(data);
      }
    });
  }

  offReceiveMessageToLobby() {
    this.base.off("ReceiveMessageToLobby");
  }

  sendMessageToLobby(msg: string) {
    this.base.invoke("SendMessageToLobby", {
      message: msg,
      server: getServerName(),
    });
  }

  // function sendMessageToGame(mgs: string) {
  //     this.base.invoke("SendMessageToGame", mgs);
  // }

  // function receiveMessageToGame(cb: CallableFunction) {
  //     this.base.on("ReceiveMessageToGame", (data: any) => {
  //         cb(data);
  //     });
  // }

  // playerBalanceSubscribe(cb: CallableFunction) {
  //   // console.log("balance subscribe");
  //   this.base.on("CheckPlayerBalance", (result) => {
  //     cb(result);
  //   });
  // }

  // checkBalance() {
  //   // console.log("check player balance");
  //   this.base.invoke("CheckPlayerBalance", { server: getServerName() });
  // }
}
