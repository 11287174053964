import React from "react";
import MessageList from "../../components/ChatArea/MessageList";
import "./GameEnd.scss";
import { Link } from "react-router-dom";
import { inject } from "mobx-react";
import { GameStateClass } from "../../store/GameStateStore";
import SendMessage from "../../components/ChatArea/SendMessage";
import {
  formatMoney,
  formatScore,
  leftPad,
} from "../../services/HelperMethodsService";
import BackgroundColor from "../../components/BackgroundColor/BackgroundColor";
import CurveWhite from "../../components/CurveWhite/CurveWhite";
// import CharacterImage from "../EntryScreen/CharacterImage";
import PlayerCount from "../../components/PlayerCountArea/PlayerCount";
import ExitQuizButton from "../../components/ExitQuizButton";
import ChatSocketService from "../../services/sockets/ChatSocketService";
import ConnectionInfo from "../../components/ConnectionInfo";
import ApiService from "../../services/ApiService";
import { toast } from "react-toastify";

declare var window: any;

interface GameEndProps {
  finalScore: number;
  position: number;
  winPrice: number;
  GameState: GameStateClass;
  top3Players: any;
  totalPlayers: number;
}

@inject("GameState")
export default class GameEnd extends React.Component<GameEndProps> {
  timeoutHandler: any = null;

  state = {
    finalScore: 0,
    tier: 0,
    winPrice: 0,
    position: 0,
    redirectToEntry: true,
    redirectToPlayAgain: false,
    windowWidth: window.innerWidth,
    top3Players: [],
    totalPlayers: 0,
    connectionId: "",
  };

  chatSocketService = ChatSocketService.getInstance();
  gameState = this.props.GameState as GameStateClass;
  gameId = (this.props as any).match.params.gameId;
  gameType = "80s";

  // totalPlayers = localStorage.getItem("totalPlayers");

  apiService = new ApiService();

  componentDidMount() {
    // this.entrySocketService.connect(() => {
    //   this.entrySocketService.getPlayerStatus({}, (data) => {
    //     this.setState({ connectionId: data.lastConnectionId });
    //   });

    //   this.entrySocketService.listenNextGameStartTime((data: any) => {
    //     this.gameState.setPlayers(data.totalPlayers, data.totalJackpot);
    //   });
    // });

    // this.chatSocketService.connect(() => {
    //   this.chatSocketService.checkBalance();
    // });
    if ((this.props as any).location.state) {
      let state = (this.props as any).location.state;
      this.setState({
        finalScore: state.finalScore,
        position: state.position,
        tier: state.tier,
        winPrice: state.winPrice,
        totalPlayers: state.totalPlayers,
        redirectToEntry: false,
        top3Players: state.top3Players,
      });

      window.setTimeout(() => {
        if (this.state.winPrice > 0) {
          window.startConfetti();
          window.setTimeout(() => {
            window.stopConfetti();
          }, 3500);
        }
      }, 100);

      console.log(this.getScore(state.top3Players, 1));
      console.log(this.getScore(state.top3Players, 2));
      console.log(this.getScore(state.top3Players, 3));

      if (
        this.getScore(state.top3Players, 1) == "0000" &&
        this.getScore(state.top3Players, 2) == "0000" &&
        this.getScore(state.top3Players, 3) == "0000"
      ) {
        toast(
          "The player with the most points wins the biggest share of the prize fund. When players have zero points they also won't receive any prize."
        );
      }
    }

    window.onresize = () => {
      this.setState({ windowWidth: window.innerWidth });
    };
  }

  componentWillUnmount() {
    if (this.timeoutHandler) {
      clearInterval(this.timeoutHandler);
    }
  }

  position() {
    if (this.state.position != 0) {
      return <p className="text-yellow">Top {this.state.position}%</p>;
    }
  }
  getGameType() {
    window.open(
      "https://5050singleplayer.catmonocle.com/?Token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpYXQiOjE2Nzk1NzMxNjgsImp0aSI6ImREd2NyWHViWFpSOUV4UVcxSzFWU1R4eDNGS3dMMjBIQzNkZWtoSW5BRUE9IiwiaXNzIjoiaWZnLmxrIiwibmJmIjoxNjc5NTczMTY4LCJleHAiOjE3MTExMDkxNjgsImRhdGEiOnsidXNlcl9pZCI6Ijc2NTRmOTcwLWFlYmQtNGM5Yy05OGM0LTY3MzYxMjZiMzE1OCIsImFwcF9pZCI6Imluc2ZyZWVnYW1lIn19.G5miIlQgggWXrzK6rspsn_yOc6yJhjTB_XF2yM5okZWXdl5fz5gETUfLk5ZzdVMIcDKlo0QOIOvuFtMXTPvdEA&GameType=23&NetworkID=25&GameLanguage=en&PlayerID=629&cashierURL=&SkinId=&DisplayName=test&RealityCheckFrequency=&country=LKA&SkinId=&GameCurrency=GBP&width=960&height=400&OperationSourceApplication=#/",
      "popup",
      "width=1200,height=680"
    );
  }
  render() {
    return (
      <div className={"game-end"}>
        <BackgroundColor gameType={this.gameType}>
          <div className="player-count-end">
            <PlayerCount
              playerCount={this.state.totalPlayers}
              money={49 + this.state.winPrice}
            />
          </div>
          <div style={{ position: "absolute", zIndex: 99 }}>
            <ExitQuizButton />
          </div>
          <div className="game-end-grid">
            <div className="first-place place animate__animated animate__delay-2s animate__zoomInDown">
              <div className="name">
                {this.getName(this.state.top3Players, 1)}
              </div>
              <img src="/assets/images/firstplace.png" />
              <div className={"score n" + this.gameType}>
                {this.getScore(this.state.top3Players, 1)}
              </div>
            </div>
            <div className="second-place place animate__animated animate__delay-1s animate__zoomInDown">
              <div className="name">
                {this.getName(this.state.top3Players, 2)}
              </div>
              <img src="/assets/images/secondplace.png" />
              <div className={"score n" + this.gameType}>
                {this.getScore(this.state.top3Players, 2)}
              </div>
            </div>
            <div className="third-place place animate__animated  animate__zoomInDown">
              <div className="name">
                {this.getName(this.state.top3Players, 3)}
              </div>
              <img src="/assets/images/thirdplace.png" />
              <div className={"score n" + this.gameType}>
                {this.getScore(this.state.top3Players, 3)}
              </div>
            </div>

            <div className="stage">
              <div className="podium">
                <div className="img" />
              </div>

              <div className={"mobile-score"}>
                <div className="row">
                  <div className="col-4 mb-1 text-center">
                    <div className="name second">
                      {this.getName(this.state.top3Players, 2)}
                    </div>
                    <div className="score">
                      {this.getScore(this.state.top3Players, 2)}
                    </div>
                  </div>
                  <div className="col-4 mb-1 text-center">
                    <div className="name first">
                      {this.getName(this.state.top3Players, 1)}
                    </div>
                    <div className="score">
                      {this.getScore(this.state.top3Players, 1)}
                    </div>
                  </div>
                  <div className="col-4 mb-1 text-center">
                    <div className="name third">
                      {this.getName(this.state.top3Players, 3)}
                    </div>
                    <div className="score">
                      {this.getScore(this.state.top3Players, 3)}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="white-area">
              {/* <div className="game-end-char">
                <img
                  className={"character-image"}
                  src="/assets/images/game-end-char.png"
                />
                <CharacterImage gameType={this.gameType} />
              </div> */}
              <CurveWhite>
                <div className="score-wrap">
                  <div className={"score-content n" + this.gameType}>
                    <h2 className={"n" + this.gameType}>Your Score</h2>
                    <span className={"score n" + this.gameType}>
                      {formatScore(this.state.finalScore)}
                    </span>
                  </div>
                  <div className={"score-content winning n" + this.gameType}>
                    <h2 className={"n" + this.gameType}>Your Winnings</h2>
                    <span className={"score n" + this.gameType}>
                      {formatMoney(this.state.winPrice)}
                    </span>
                  </div>
                  <div className="button-row">
                    <div
                      onClick={() => {
                        this.getGameType();
                      }}
                      className={"btn singleplayer-btn n" + this.gameType}
                    >
                      <div className="text">TRY OUR A/B QUIZ</div>
                      <div className="up-img"></div>
                    </div>

                    <div
                      onClick={() => {
                        window.location.replace("/#/entry/");
                      }}
                      className={"text-or n" + this.gameType}
                    >
                      OR
                    </div>

                    <div
                      onClick={() => {
                        window.location.replace("/#/entry/");
                      }}
                      className={"btn lobby-btn n" + this.gameType}
                    >
                      <div className="text">Play Again</div>
                      <img src="/assets/images/arrow_back.png" />
                    </div>
                  </div>
                  {/* <div
                    onClick={() => {
                      window.location.replace("/#/entry/");
                    }}
                  >
                    <div className={"btn lobby-btn n" + this.gameType}>
                      <img src="/assets/images/arrow_back.png" />
                      <div className="text">Play Again</div>
                    </div>
                  </div> */}
                </div>
              </CurveWhite>
            </div>
            <div className="message-list">
              <MessageList />
            </div>

            <SendMessage gameType={this.gameType} />
          </div>
          <ConnectionInfo connectionId={this.state.connectionId} />
        </BackgroundColor>
      </div>
    );
    // return this.layoutBasedOnWidth();
  }

  getName(top3Players: any, position: number): React.ReactNode {
    position -= 1;

    let name = "...";
    if (top3Players[position]) {
      name = top3Players[position].name;
    }

    return name;
  }

  getScore(top3Players: any, position: number): React.ReactNode {
    position -= 1;

    if (top3Players[position]) {
      let score = top3Players[position].score;
      return leftPad(score, 4);
    }

    return "0000";
  }
}
function getSkinId() {
  throw new Error("Function not implemented.");
}
