import Config from "./../../Config";
import {
  HubConnectionBuilder,
  HubConnection,
  HubConnectionState,
} from "@aspnet/signalr";
import { SocketState } from "../../store/GameStateStore";
import { toast } from "react-toastify";
import AlreadyInMessage from "../../components/AlreadyInMessage";
import { getAuthUser } from "../HelperMethodsService";
import * as signalR from "@aspnet/signalr";

const BaseSocketService = () => {
  let initConnection = false;
  let connection!: HubConnection;
  let invokePool: any[] = [];
  let allOnMessages: any[] = [];

  let socketState = SocketState;
  let isForceDisconnected: boolean = false;
  let reconnectTryTime = 0;
  let maxReconnectTryTime = 3;

  function connect(
    hubName: string,
    connectedCallBack: CallableFunction,
    disconnectedCallBack: CallableFunction
  ) {
    socketState.working();

    let token = getAuthUser();
    let config = new Config();
    let url = config.getBaseURL() + "/hub/" + hubName + "/";
    connection = new HubConnectionBuilder()
      .withUrl(url, {
        // skipNegotiation: true,
        transport: signalR.HttpTransportType.LongPolling,
        accessTokenFactory: () => token,
      })
      .build();

    // remove server timeout
    connection.serverTimeoutInMilliseconds = 180000; // 3 min

    connection
      .start()
      .then(() => {
        connected(connectedCallBack);
        //handle api errors
        // on("error", (error) => {
        //     if (error.type == "same_user") {
        //         toast.info(AlreadyInMessage, {
        //             autoClose: false,
        //             toastId: 1,
        //         });
        //     } else {
        //         if (!error.message) {
        //             error.message = error.type
        //                 ? error.type
        //                 : "unknown error please try again";
        //         }
        //     }
        //     // socketState.serverNotice(error.message);
        //     toast.error(error.message, { autoClose: false });
        // });

        connection.onclose(() => {
          disconnected(disconnectedCallBack);
        });
      })
      .catch((err: any) => {
        if (err && err.statusCode == 401) {
          // toast.error("401 You are not authorized to view the content");
          return;
        } else {
          // toast.error(
          //   "Error-004. The game could not connect to the server. Please check your connection and try again.",
          //   {
          //     autoClose: false,
          //     toastId: "004",
          //   }
          // );
        }
        disconnected();
      });
  }

  function disconnected(callBack: CallableFunction = () => {}) {
    callBack();

    if (!isForceDisconnected) {
      socketState.error("");

      //trying to reconnect
      window.setTimeout(() => {
        // this.connect();

        if (reconnectTryTime > maxReconnectTryTime) {
          socketState.error("");
          isForceDisconnected = true;
        } else {
          isForceDisconnected = false;
        }

        reconnectTryTime++;
      }, 1000);
    }
  }

  function connected(callBack: CallableFunction) {
    socketState.ready();
    callBack();

    // call to all callback functions
    // invokePool.forEach(invoke => {
    //     connection.invoke(invoke.message);
    // });
  }

  function disconnect() {
    isForceDisconnected = true;
    connection?.stop();
  }

  function invoke(message: string, data?: any) {
    if (!connection) {
      console.error("called invoke before connection was made", message);
      return;
    }
    if (connection.state == HubConnectionState.Connected) {
      if (data) {
        connection.invoke(message, data);
      } else {
        connection.invoke(message);
      }
    } else {
      //add to callback pool
      invokePool.push({ message: message });
    }
  }

  function on(message: string, cb: any) {
    allOnMessages.push({ message });
    connection?.on(message, cb);
  }

  function off(message: string) {
    if (connection) {
      connection?.off(message);
    }
  }

  function offAll() {
    allOnMessages.forEach((message) => {
      connection?.off(message.message);
    });
  }

  return {
    connect,
    disconnect,
    on,
    off,
    invoke,
    offAll,
  };
};

export default BaseSocketService;
