import React, { createRef, useEffect, useRef, useState } from "react";
import MessageList from "../../components/ChatArea/MessageList";

import "./Game.scss";
import "react-toastify/dist/ReactToastify.css";
import {
  PlayerPlacements,
  TopPlayers,
} from "../../components/PlayerPlacements/PlayerPlacements";
import SendMessage from "../../components/ChatArea/SendMessage";
import { toast } from "react-toastify";
import {
  formatMoney,
  handleErrors,
  validateBot,
} from "../../services/HelperMethodsService";
import BackgroundColor from "../../components/BackgroundColor/BackgroundColor";
import CurveWhite from "../../components/CurveWhite/CurveWhite";
import IntroAnimation from "../../components/IntroAnimation/IntroAnimation";
import Loader from "react-loader-spinner";
import { Fragment } from "react";
import AvailableMoney from "../../components/PlayerCountArea/AvailableMoney";
import ApiService from "../../services/ApiService";
import { useHistory, useParams } from "react-router";
import { API, graphqlOperation } from "aws-amplify";
import { onUpdateRolloutQuizzesSession } from "../../graphql/subscriptions";
import ConnectionInfo from "../../components/ConnectionInfo";

declare var window: any;

enum JokerStatus {
  IsEnable,
  IsPlaying,
  IsPlayed,
  IsTutorial,
}

export default function Game() {
  const { game, gameId } = useParams() as any;
  let isMounted = true;
  const history = useHistory();

  const [totalJackpot, setTotalJackpot] = useState(0);
  const [question, setQuestion] = useState(null as any);
  const [answers, setAnswers] = useState([]);
  const [questionTime, setQuestionTime] = useState(-99);
  const [players, setPlayers] = useState([] as any[]);
  const [myPlayerId, setMyPlayerId] = useState(0);
  const [correctAnswer, setCorrectAnswer] = useState("");
  const [playerAnswer, setPlayerAnswer] = useState("");
  const [jokerStatus, setJokerStatus] = useState(JokerStatus.IsTutorial);
  const [lastScore, setLastScore] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isSendingAnswer, setIsSendingAnswer] = useState(false);
  const [qNumber, setQuestionNumber] = useState(0);
  const [checkJokerPlay, setCheckJokerPlay] = useState(0);
  const [isJokerMessageShown, setIsJokerMessageShown] = useState(false);
  // let isJokerMessageShown = false;
  let sub;
  const apiService = new ApiService();

  const [QTimer, setTimer] = useState(0);

  useEffect(() => {
    getInitGameData();
    subscribeGame();

    return () => {
      isMounted = false;
      if (sub) {
        console.log("unsubed game");
        sub.unsubscribe();
      }
    };
  }, []);

  useEffect(() => {
    if (!answers?.length) {
      getInitGameData();
      setLastScore(0);
    }
  }, [answers]);

  // let tout;
  // useEffect(() => {
  //   if (questionTime) {
  //     let qt = questionTime;

  //     setTimeout(() => {
  //       let newqt = qt - 1;
  //       setTimer(newqt);
  //     }, 1000);
  //   }
  // }, [questionTime, QTimer]);

  useEffect(() => {
    if (qNumber != 9) {
      return;
    }
    if (
      jokerStatus == JokerStatus.IsEnable ||
      jokerStatus == JokerStatus.IsTutorial
    ) {
      setJokerStatus(JokerStatus.IsPlaying);
      if (!isJokerMessageShown) {
        toast(
          "If you haven't played the Joker, it will autoplay on the last question",
          {
            className: "toastr-wrap",
            toastId: "joker",
          }
        );
      }
      setIsJokerMessageShown(true);
    }
  }, [checkJokerPlay]);

  async function getInitGameData() {
    try {
      let data = (await apiService.currentQuestions(gameId)) as any;
      data = data.data;

      let datJackpot = data.jackpot;
      let datGameStatus = data.gameStatus;
      let isJokerPlayed = data.isJokerPlayed;
      let dataGamePlayers = data.gamePlayers;
      setMyPlayerId(data.myPlayerId);
      // setTotalJackpot(datJackpot);
      setTotalJackpot(20);
      let dataLastScore = data.lastScore;

      if (dataLastScore) {
        setLastScore(dataLastScore - lastScore);
        console.log("dls", dataLastScore);
        // setTimeout(() => {
        //   setLastScore(0);
        // }, 10000);
      }
      if (dataGamePlayers?.gamePlayers) {
        setPlayers(dataGamePlayers?.gamePlayers);
      }
      if (
        datGameStatus == "CrashedAfterStarted" ||
        datGameStatus == "CrashedAfterStartedFromSeed"
      ) {
        //check errors
        apiService.getGameStatus(gameId).then((data: any) => {
          data = data.data;

          if (data.errorMessage) {
            toast.error(data.errorMessage);
          }

          history.push({
            pathname: "/entry/" + game,
          });
        });
      } else if (datGameStatus == "Finishing") {
        setIsLoading(true);
      } else if (datGameStatus == "Finished") {
        setIsLoading(true);

        if (window.location.hash.includes("#/game/")) {
          apiService.getGameEndData(gameId).then((endData) => {
            endData = endData.data;
            history.push({
              pathname: "/game-end/" + game + "/" + gameId,
              state: endData,
            });
          });
        }
      }

      console.log("apistatus", data);

      if (isJokerPlayed) {
        setJokerStatus(JokerStatus.IsPlayed);
      } else {
        setJokerStatus(JokerStatus.IsEnable);
      }
    } catch (error) {
      handleErrors(error);
    }
  }

  async function subscribeGame() {
    console.log("game subbed");

    sub = (
      (await API.graphql(
        graphqlOperation(onUpdateRolloutQuizzesSession, {
          id: gameId,
        })
      )) as any
    ).subscribe((data) => {
      var question =
        data?.value?.data?.onUpdateRolloutQuizzesSession?.lastQuestion;
      var answers =
        data?.value?.data?.onUpdateRolloutQuizzesSession?.lastAnswers;
      var correctAnswer =
        data?.value?.data?.onUpdateRolloutQuizzesSession?.lastCorrectAnswer;
      var qCountTimer =
        data?.value?.data?.onUpdateRolloutQuizzesSession?.questionCountTimer;
      var status = data?.value?.data?.onUpdateRolloutQuizzesSession?.status;
      var questionNumber =
        data?.value?.data?.onUpdateRolloutQuizzesSession?.currentQuestionNumber;

      console.log("game data", data);

      if (questionNumber) {
        setQuestionNumber(questionNumber);

        if (questionNumber == 9) {
          setCheckJokerPlay(Math.random());
        }
      }

      if (status == "Finishing") {
        setIsLoading(true);

        setTimeout(() => {
          getInitGameData();
        }, 5000);
      } else if (status == "Finished") {
        getInitGameData();
      } else if (status == "CrashedAfterStarted") {
        getInitGameData();
      }

      if (question) {
        setQuestion(question);
        if (!answers) {
          console.log("qca");

          setAnswers([]);
          setCorrectAnswer("");
          setLastScore(0);
          setPlayerAnswer("");
        }
      }
      if (answers) {
        setAnswers(JSON.parse(answers));
      }

      if (qCountTimer || qCountTimer == 0) {
        setQuestionTime(qCountTimer);
      }

      if (correctAnswer) {
        console.log("ca");
        setCorrectAnswer(correctAnswer);
        getInitGameData();
      }
    });
  }

  function sendAnswer(answer: string) {
    if (isSendingAnswer || correctAnswer) {
      return;
    }
    setLastScore(0);
    try {
      setIsSendingAnswer(true);
      validateBot(async (gToken) => {
        let result = await apiService.playerSendAnswer({
          Answer: answer,
          GCaptcha: gToken,
          GameId: gameId,
        });
        let data = result.data;

        setPlayerAnswer(data?.answer);
        setIsSendingAnswer(false);
      }, "PlayerAnswer");
    } catch (error) {
      handleErrors(error);
      setIsSendingAnswer(false);
    }
  }

  async function sendJokerPlay() {
    setJokerStatus(JokerStatus.IsPlaying);
    try {
      let result = await apiService.sendJokerPlay(gameId);
    } catch (error) {
      handleErrors(error);
      setJokerStatus(JokerStatus.IsEnable);
    }
    // this.gameSocket.sendJokerPlay();
    // this.setState({ jokerStatus: JokerStatus.IsPlaying });
  }

  function getCorrectAnswerMark(ca: string) {
    if (ca == correctAnswer) {
      return (
        <div className={"answer-mark correct-answer n" + game}>
          <div className="mark-correct">
            <img src="/assets/images/tick.png" />
            {/* <i className="fas fa-check-circle" /> */}
          </div>
        </div>
      );
    }
  }

  function getWrongAnswerMark(answer: any) {
    if (correctAnswer && correctAnswer != answer && playerAnswer == answer) {
      return (
        <div className="answer-mark wrong">
          <div className="mark-wrong">
          {/* <img src="/assets/images/wrong2.png" /> */}
            <i className="fas fa-times"></i>
          </div>
        </div>
      );
    }
  }

  function questionText() {
    if (!question) {
      return <span className="waiting">...</span>;
    }
    return <div className="question-text">{question}</div>;
  }

  function getAnswerLastScore(currentAnswer: string) {
    let symbol = "+";
    if (lastScore <= 0) {
      symbol = "";
    }

    if (currentAnswer == playerAnswer && correctAnswer) {
      return (
        <div className="last-score">
          <span className="text">{lastScore ? lastScore : ""}</span>
          <span className="symbol">{symbol}</span>
        </div>
      );
    }

    return <Fragment />;
  }

  function getAnswerPoints(correctAnswer: string) {
    return <></>;
  }

  function questionTimer() {
    if (questionTime == -99) {
      return <></>;
    }

    if (!correctAnswer) {
      return (
        <div>
          <div className={"timer n" + game}>
            <div className="t">{questionTime}</div>
            <div
              className={
                questionTime
                  ? "rotate img n" + game
                  : "rotate paused img n" + game
              }
            />
          </div>
        </div>
      );
    }
  }

  function jokerSection() {
    if (jokerStatus == JokerStatus.IsPlaying) {
      return (
        <div className={"joker-wrap"}>
          <div className={"spinner-wrap"}>
            <Loader type="Oval" color="#ff855e" height={51} width={51} />
          </div>
          <img
            className="double-points-used"
            src="/assets/images/doublepoints.png"
          />
        </div>
      );
    } else if (jokerStatus == JokerStatus.IsPlayed) {
      return (
        <div className={"joker-wrap"}>
          <img
            className="double-points-used"
            src="/assets/images/doublepoints_used.png"
          />
        </div>
      );
    } else if (jokerStatus == JokerStatus.IsEnable) {
      return (
        <div className={"joker-wrap"}>
          <div
            onClick={() => {
              if (!correctAnswer) {
                sendJokerPlay();
              } else {
                toast("The Joker cannot be used after the answer is revealed.");
              }
            }}
            className="double-point-icon"
          >
            <img
              className="double-points"
              src="/assets/images/doublepoints.png"
            />
          </div>
        </div>
      );
    } else if (jokerStatus == JokerStatus.IsTutorial) {
      return (
        <div
          className={"joker-tutorial-wrap animate__animated animate__fadeIn"}
        >
          <div className={"text"}>
            Tap this icon to use your double points token on this question
          </div>
          <div className="icon">
            <img
              className="doublepoints"
              src="/assets/images/doublepoints.png"
            />
          </div>
        </div>
      );
    }
  }

  function questionNumber(questionNumber: number): React.ReactNode {
    if (!questionNumber && questionNumber != 0) {
      return <></>;
    }
    return (
      <div className={"question-number n" + game}>
        <div className="icon i-question" />
        {/* <i className="fas fa-question-circle" /> */}
        {questionNumber + 1}/10
      </div>
    );
  }

  return (
    <div>
      <div className="game">
        {/*{this.redirectToGameEnd()}*/}
        <BackgroundColor gameType={game}>
          <div className="game-screen-wrap">
            <div className="gift-icon">
              <i className="fa fa-gift" />

              {isLoading ? (
                <div style={{ position: "relative", zIndex: 5 }}>
                  <div className="loading-icon-end fas fa-circle-notch fa-spin"></div>
                </div>
              ) : (
                <Fragment />
              )}
            </div>

            <div className="jackpot">
              <div>
                <span className="title">Prize Fund:</span>
                <span className="amount">{formatMoney(totalJackpot)}</span>
              </div>

              {isLoading ? (
                <div>
                  <span className="title cal-text mt-2">
                    CALCULATING <br /> WINNINGS
                  </span>
                </div>
              ) : (
                <Fragment />
              )}
            </div>

            <TopPlayers
              gameType={game}
              players={players}
              myPlayerId={myPlayerId}
            />

            <PlayerPlacements
              gameType={game}
              players={players}
              myPlayerId={myPlayerId}
            />

            <div className="player-counter-wrap">
              <div className="player-count">
                <div className="counter player-counter">
                  <div className="crow">
                    <div className="">
                      <span className="icon lobby light"></span>
                    </div>
                    <div className=" number light">{players?.length}</div>
                  </div>
                  <div className="available-money-wrap">
                    <AvailableMoney money={49} />
                  </div>
                </div>
              </div>
            </div>

            <div className="message-list">
              <MessageList />
            </div>

            <div className="question-wrap">
              <IntroAnimation gameType={game} />
              <CurveWhite />
              <div className="game-background">
                <div className="quizwood" />
              </div>

              {jokerSection()}
              <div className="question">
                {questionNumber(qNumber)}
                {questionText()}
                {questionTimer()}
              </div>

              <div className="answers">
                {answers?.map?.((answer: any, index) => {
                  var answerClasses = "answer n" + game + " ";
                  if (playerAnswer) {
                    answerClasses += "cursor-disabled ";
                    answerClasses += answer == playerAnswer ? "selected" : "";
                  }

                  if (answer == correctAnswer) {
                    answerClasses += " correct-answer";
                  } else {
                    if (correctAnswer) {
                      answerClasses += " wrong-answer";
                    }
                  }

                  return (
                    <div
                      className={answerClasses}
                      onClick={() => {
                        if (playerAnswer.trim() == "") {
                          sendAnswer(answer);
                          setPlayerAnswer(answer);
                        }
                      }}
                      key={index}
                    >
                      {getAnswerPoints(answer)}
                      <div className="answer-txt">{answer}</div>
                      {getCorrectAnswerMark(answer)}
                      {getWrongAnswerMark(answer)}
                      {getAnswerLastScore(answer)}
                    </div>
                  );
                })}
              </div>
            </div>
            <SendMessage gameType={game} />
          </div>

          <ConnectionInfo connectionId={gameId} />
        </BackgroundColor>
      </div>
    </div>
  );
}
