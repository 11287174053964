import React from "react";
import { routes } from "../routes";
import { toast, ToastContainer } from "react-toastify";

import "./Main.scss?v1";
import ConnectionMessage from "../components/ConnectionMessage/ConnectionMessage";
import ExitQuizButton from "../components/ExitQuizButton";
// import GameSocket from "../services/sockets/GameSocket";
import ChatSocketService from "../services/sockets/ChatSocketService";
import { Fragment, useEffect } from "react";
import ApiService from "../services/ApiService";

function Main() {
  const apiService = new ApiService();

  useEffect(() => {
  }, []);

  return (
    <>
      <ToastContainer />
      <ConnectionMessage />
      <div className="app-container">{routes()}</div>
    </>
  );
}

export default Main;
