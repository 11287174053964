/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateRolloutQuizzesSession = /* GraphQL */ `
  subscription OnCreateRolloutQuizzesSession($id: String) {
    onCreateRolloutQuizzesSession(id: $id) {
      id
      createdDate
      status
      entryCountTime
      lastQuestion
      lastAnswers
      lastCorrectAnswer
      questionCountTimer
      jackpot
      totalPlayers
      serverName
      gamePlayersStatus
      currentQuestionNumber
    }
  }
`;
export const onUpdateRolloutQuizzesSession = /* GraphQL */ `
  subscription OnUpdateRolloutQuizzesSession($id: String) {
    onUpdateRolloutQuizzesSession(id: $id) {
      id
      createdDate
      status
      entryCountTime
      lastQuestion
      lastAnswers
      lastCorrectAnswer
      questionCountTimer
      jackpot
      totalPlayers
      serverName
      gamePlayersStatus
      currentQuestionNumber
    }
  }
`;
export const onDeleteRolloutQuizzesSession = /* GraphQL */ `
  subscription OnDeleteRolloutQuizzesSession($id: String) {
    onDeleteRolloutQuizzesSession(id: $id) {
      id
      createdDate
      status
      entryCountTime
      lastQuestion
      lastAnswers
      lastCorrectAnswer
      questionCountTimer
      jackpot
      totalPlayers
      serverName
      gamePlayersStatus
      currentQuestionNumber
    }
  }
`;
