import { toast } from "react-toastify";

export function formatCountDown(time: number) {
  if (!time) {
    return "00.00";
  }

  return new Date(time * 1000).toISOString().substr(14, 5);
}

export function GameTypesList() {
  const n80s = "80s";
  const n90s = "90s";
  const n00s = "00s";
  const nMusic = "music";
  const nHollywood = "hollywood";

  return {
    n80s,
    n00s,
    n90s,
    nMusic,
    nHollywood,
  };
}

export function getServerName() {
  return 9991;
}
// function generateNumber(input) {
//     var hash = 0,
//         len = input.length;
//     for (var i = 0; i < len; i++) {
//         hash = (hash << 5) - hash + input.charCodeAt(i);
//         hash |= 0; // to 32bit integer
//     }
//     return hash;
// }

export function handleErrors(error) {
  if (error.response?.data?.message) {
    toast.error(error.response?.data?.message);
    console.log(error.response?.data?.message,"error.response?.data?.message");
  } else if (error?.message) {
    toast.error(error?.message);
    console.log(error?.message,"error?.message");
  } else {
    toast.error("Request failed!");
  }
}

export function urlParamsAsync() {
  let url_string = window.location.href; //window.location.href
  let url = new URL(url_string);

  return {
    token: url.searchParams.get("Token"),
    gameType: url.searchParams.get("GameType"),
    networkID: url.searchParams.get("NetworkID"),
    playerID: url.searchParams.get("PlayerID"),
    serverName: getServerName().toString(),
    name: url.searchParams.get("DisplayName"),
  };
}

export function isEmpty(obj) {
  return Object.keys(obj).length === 0;
}

export function validateBot(callBack: CallableFunction, actionName: string) {
  callBack();
  let grecaptcha = (window as any).grecaptcha;

  // grecaptcha.ready(function () {
  //   grecaptcha
  //     .execute("6LeU7qcaAAAAAGh0Dbj_oxtuapuOSbO1eED_ODb9", {
  //       action: actionName,
  //     })
  //     .then(function (token) {
  //       callBack(token);
  //     })
  //     .catch((err) => {
  //       console.log("gr error", err);
  //       alert(
  //         "We are unable to load Google reCAPTCHA!. Please reload the game"
  //       );
  //     });
  // });
}


let currentIp = "";
export async function getCurrentIP() {
  console.log("current ip", currentIp);

  if (!currentIp) {
    let jQuery = (window as any).$;
    return new Promise((resolve, reject) => {
      jQuery.getJSON(
        "https://888.catmonocle.cloud/auth/ip",
        (data) => {
          let ip = data.ip;
          currentIp = ip;
          resolve({
            clientIp: ip,
          });
        },
        () => {
          reject("failed to get ip address");
        }
      );
    });
  }
  return new Promise((resolve, reject) => {
    return resolve({
      clientIp: currentIp,
    });
  });
}

export function GetGameName(gameType: string) {
  switch (gameType) {
    case GameTypesList().n00s: {
      return "00'S QUIZ";
    }
    case GameTypesList().n80s: {
      return "80'S QUIZ";
    }
    case GameTypesList().n90s: {
      return "90'S QUIZ";
    }
    case GameTypesList().nMusic: {
      return "MUSIC QUIZ";
    }
    case GameTypesList().nHollywood: {
      return "Hollywood Quiz";
    }
  }
}

export function strToColor(str) {
  if (!str) {
    return "";
  }
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = "#";
  for (let i = 0; i < 3; i++) {
    let value = (hash >> (i * 8)) & 0xff;
    colour += ("00" + value.toString(16)).substr(-2);
  }
  return colour;
}

export function formatMoney(value: number) {
  var val = value?.toLocaleString("en-US", {
    style: "currency",
    currency: "GBP",
  });

  if (val?.includes("NaN")) {
    val = "...";
  }

  return val;
}

export function formatScore(value: number) {
  if (value == null) {
    value = 0;
  }
  return ("0000" + value).slice(-4);
}

export function warnRefresh() {
  window.onbeforeunload = (e: any) => {
    e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
    // Chrome requires returnValue to be set
    e.returnValue = "";
  };
}

export function removeRefreshWarn() {
  window.onbeforeunload = function () {
    // blank function do nothing
  };
}

export function leftPad(number: number, targetLength: number) {
  var output = number + "";
  while (output.length < targetLength) {
    output = "0" + output;
  }
  return output;
}

export function getAuthUser(): any {
  let str = localStorage.getItem("auth");
  return str;
}
// export function getPlayerId() {
//     return localStorage.getItem("playerId");
// }

export function getUserId() {
  return localStorage.getItem("userId");
}
export function setAuthUser(auth: any) {
  localStorage.setItem("auth", auth.jwt);
  if (auth.playerId) {
    localStorage.setItem("playerId", auth.playerId);
  }
  if (auth.userId) {
    localStorage.setItem("userId", auth.userId);
  }
}

// export class InstanceStore {
//     public static ChatSocketInstance: any;
// }
