// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const config =  {
    API: {
        GraphQL: {
          endpoint: 'https://rjrmaxgxv5g7rprksvtq2g4l6a.appsync-api.us-east-2.amazonaws.com/graphql',
          region: 'us-east-2',
          defaultAuthMode: 'apiKey',
          apiKey: 'da2-2d6poj2aezcwtiai6vl653tvq4'
        }
    }
};

export default config;