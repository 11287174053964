import React from "react";
import ReactDOM from "react-dom";
import Main from "./pages/Main";
import { Provider } from "mobx-react";
import { SocketState, GameState, ChatMessages } from "./store/GameStateStore";
import Store from "./store/Store";
import Amplify from "aws-amplify";
import awsconfig from "./aws-exports";

Amplify.configure(awsconfig);

const Root = (
  <Provider
    SocketState={SocketState}
    GameState={GameState}
    ChatMessages={ChatMessages}
  >
    <Store>
      <Main />
    </Store>
  </Provider>
);

ReactDOM.render(Root, document.getElementById("root"));

// serviceWorker.register();
