import React, { createContext, useState } from "react";

export const ChatStore = createContext([] as any[]);
export const PlayerBalance = createContext([] as any[]);
// export const ConnectionId = createContext([] as any[]);

export default function Store({ children }) {
  const [messages, setMessages] = useState([]);
  const [playerBalance, setPlayerBalance] = useState([]);
  // const [connectionId, setConnectionId] = useState("");
  // const [totalJackpot, setTotalJackpot] = useState(null as any);
  return (
    <ChatStore.Provider value={[messages, setMessages]}>
      <PlayerBalance.Provider value={[playerBalance, setPlayerBalance]}>
        {/* <ConnectionId.Provider value={[connectionId, setConnectionId]}> */}
        {children}
        {/* </ConnectionId.Provider> */}
      </PlayerBalance.Provider>
    </ChatStore.Provider>
  );
}
