import React from "react";

// components
import Game from "./pages/Game/Game";
import EntryScreen from "./pages/EntryScreen/EntryScreen";
import GameEnd from "./pages/GameEnd/GameEnd";
import { Route, HashRouter } from "react-router-dom";
import QuizSchedule from "./pages/QuizSchedule/QuizSchedule";

export function routes() {
  return (
    <HashRouter>
      <Route path="/" exact component={QuizSchedule} />
      <Route path="/entry/:isForced?" exact component={EntryScreen} />
      <Route path="/game/:game/:gameId" component={Game} />
      <Route path="/game-end/:game/:gameId" component={GameEnd} />
      <Route
        path="/admin/*"
        component={() => {
          window.location.href = "https://888.catmonocle.com/admin";
          return null;
        }}
      />
    </HashRouter>
  );
}
