import React, { Fragment } from "react";
import "./PlayerCount.scss";
import { GameStateClass } from "../../store/GameStateStore";
import { inject } from "mobx-react";
import AvailableMoney from "./AvailableMoney";

interface PlayerCountPropTypes {
  playerCount?: any;
  money?: number;
}

@inject("GameState")
export default class PlayerCount extends React.Component<PlayerCountPropTypes> {
  gameState = (this.props as any).GameState as GameStateClass;

  render() {
    return (
      <Fragment>
        <div className="player-count">
          <div className="row no-gutters justify-content-between align-items-center">
            <div className="col-auto counter player-counter">
              <div className="row no-gutters align-items-center">
                <div className="col-auto">
                  <span className="icon lobby light"></span>
                </div>
                <div className="col-auto number light">
                  {this.props.playerCount
                    ? this.props.playerCount
                    : this.gameState.state.players}
                </div>
                {/* <div className="col-auto settings-icon">
                                <i className="fas fa-cog"></i>
                            </div> */}
                <AvailableMoney money={this.props.money} />
                <div className="col-auto"></div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
