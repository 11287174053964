import React, { Fragment, useContext, useEffect, useState } from "react";
import ApiService from "../../services/ApiService";
import { formatMoney } from "../../services/HelperMethodsService";
import ChatSocketService from "../../services/sockets/ChatSocketService";
import { PlayerBalance } from "../../store/Store";

export default function AvailableMoney(props: { money? }) {
  const [balance, setBalance] = useState(0);
  const [isBalanceSet, setIsBalanceSet] = useState(false);
  var chatS = ChatSocketService.getInstance();
  const [isMounted, setIsMounted] = useState(false);
  const apiService = new ApiService();

  useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);

  useEffect(() => {
    // checkMoney();
  }, []);

  // async function checkMoney() {
  //   let data = (await apiService.getBalance()) as any;
  //   data = data.data;
  //   // console.log("money", data?.availableMoneyInBankrollCurrency);
  //   setBalance(data?.availableMoneyInBankrollCurrency);
  //   if (data?.availableMoneyInBankrollCurrency) {
  //     setIsBalanceSet(true);
  //   }
  //   setTimeout(() => {
  //     checkMoney();
  //   }, 30000);
  // }

  // if (!isBalanceSet) {
  return (
    <div className="available-money-wrap ml-3">
      <span style={{ color: "white" }}>
        {formatMoney(props.money ? props.money : 20)}
      </span>
    </div>
  );
  // }

  // return (
  //   <div className="available-money-wrap text-white">
  //     {/* <i className="fas  fa-pound-sign"></i> */}
  //     <span>{formatMoney(props.money ? props.money : 20)}</span>
  //   </div>
  // );
}
