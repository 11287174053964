import { getServerName, GetGameName } from "./services/HelperMethodsService";

export default class Config {
  isOnDebugMode =
    !process.env.NODE_ENV || process.env.NODE_ENV === "development";

  getBaseURL() {
    var gameType = this.getGameType();

    let prefix = "";
    if (this.isOnDebugMode) {
      prefix = "_d";
    }

    let url = "" as any;
    // if (gameType == "23001") {
    //     url = process.env["REACT_APP_BASE_URL_Hollywood" + prefix];
    // } else if (gameType == "23002") {
    //     url = process.env["REACT_APP_BASE_URL_80s" + prefix];
    // } else if (gameType == "23003") {
    //     url = process.env["REACT_APP_BASE_URL_90s" + prefix];
    // } else if (gameType == "23004") {
    //     url = process.env["REACT_APP_BASE_URL_00s" + prefix];
    // } else if (gameType == "23005") {
    //     url = process.env["REACT_APP_BASE_URL_Music" + prefix];
    // }

    url = process.env["REACT_APP_BASE_URL" + prefix];
    //console.log("server", url);
    return url;
  }

  getGameType() {
    let url_string = (window as any).location.href; //window.location.href
    let url = new URL(url_string);

    return url.searchParams.get("GameType");
  }
}
